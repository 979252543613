import React from "react";
import "./Logo.Component.scss";

const Logo = ({
  text = "akr.is",
  subtitle = undefined,
  position = "standalone",
  size = "medium",
  cursor = true,
  animateCursor = true,
}: {
  text?: string;
  subtitle?: string | string[] | undefined;
  position?: "standalone" | "menu" | "footer";
  size?: "small" | "medium" | "large" | "xlarge";
  cursor?: boolean;
  animateCursor?: boolean;
}) => {
  //   const subtitleRender = <div className="akr_is-logo_subtitle">{subtitle}</div>;
  const cursorBlock = (
    <span
      className={`akr_is-logo_cursorBlock ${
        animateCursor ? "animated-cursor" : null
      }`}
    >
      ▌
    </span>
  );
  return (
    <div className={`akr_is-logo ${position} ${size}`}>
      {text}
      {cursor ? cursorBlock : null}
    </div>
  );
};

export default Logo;
