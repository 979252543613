import React from "react";

import { ChakraProvider } from "@chakra-ui/react";

import "./App.scss";
import Logo from "./Components/Logo/Logo.Component";
import theme from "./theme";

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <div className="akr_is">
        <div className="akr_is-container centered">
          <Logo text="akr.is" size="xlarge"></Logo>
        </div>
      </div>
    </ChakraProvider>
  );
};

export default App;
